.newsletter {
	background-color: #EB7310;
	margin-top: 50px;
	padding: 50px 0;
	color: #fff;
}

.newsletter .left,
.newsletter .right {
	padding-right: 50px;
}

.newsletter h1 {
	color: #fff;
	font-size: 30px;
	font-weight: 500;
	margin-bottom: 15px;
}

.newsletter .right {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.newsletter input {
	width: 100%;
	padding: 20px;
	border: none;
	outline: none;
	height: 30px;
}

.newsletter i {
	padding: 12px;
	color: #EB7310;
	background-color: #fff;
}

footer {
	background-color: #eeeeee;
}
footer .container {
	display: grid;
	grid-template-columns: 3fr 2fr 2fr 4fr 3fr;
	grid-gap: 30px;
}
@media screen and (max-width: 768px) {
	footer .container {
		grid-template-columns: repeat(2, 1fr);
	}
}
footer .logo span {
	color: #EB7310;
	font-size: 14px;
}
footer .logo p {
	color: grey;
	margin: 30px 0 15px 0;
}
footer .logo .icon {
	background-color: #EB7310;
	color: white;
	margin-right: 10px;
}
footer h3 {
	margin-bottom: 40px;
	font-weight: 500;
}
footer ul li {
	margin-bottom: 20px;
}
footer .link li::before {
	content: "⟶";
	color: #EB7310;
	margin-right: 5px;
}
footer .items {
	margin-bottom: 30px;
}
footer .items img {
	width: 50px;
	height: 50px;
	border-radius: 5px;
	margin-right: 10px;
	object-fit: cover;
}
footer .items span,
footer .items i {
	font-size: 12px;
	color: #EB7310;
	margin-right: 5px;
	text-transform: capitalize;
}
footer h4 {
	font-weight: 400;
	margin-top: 5px;
}
footer .last ul li {
	display: flex;
	margin-bottom: 50px;
}
footer .last i {
	width: 60px;
	font-size: 20px;
	color: #EB7310;
}
.legal {
	text-align: center;
	padding: 50px 0;
	color: grey;
	background-color: #e9e9e9;
}
.legal p {
	font-size: 15px;
}
.legal i {
	color: #EB7310;
}
