.design .img {
	position: relative;
	width: 420px; /* Set a fixed width for the image */
	height: 320px; /* Set a fixed height for the image */
	overflow: hidden; /* Ensure that the image doesn't overflow */
}

.design img {
	width: 100%;
	height: 100%;
	object-fit: cover; /* Ensure the image covers the container while maintaining its aspect ratio */
}

.design .items {
	transition: 0.5s;
}

.overlay {
	position: absolute;
	bottom: 0;
	z-index: 11;
	display: flex;
	flex-direction: column;
	padding: 20px;
	opacity: 0;
	transition: 0.5s;
}

.overlay i {
	background-color: #000;
	color: #fff;
	margin: 5px;
	transition: 0.5s;
}

.design .details {
	padding: 30px;
	text-align: center;
}

.design .details h2 {
	font-weight: 500;
	font-size: 20px;
	transition: 0.05s;
}

.design .details p {
	font-size: 15px;
	color: grey;
	margin-top: 10px;
	transition: 0.05s;
}

.design .items:hover {
	background-color: #EB7310;
	color: #fff;
	cursor: pointer;
}

.design .items:hover p {
	color: #fff;
}

.design .items:hover .overlay {
	opacity: 1;
}

.design .items:hover .overlay i:hover {
	background-color: #EB7310;
	color: #fff;
}
