@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}

body {
	font-family: "Roboto", sans-serif;
	/* background-color: #858484; */
	background-color: #f8f8f8;
}

.container {
	max-width: 85%;
	margin: auto;
}

.flexSB {
	display: flex;
	justify-content: space-between;
}

.icon {
	width: 40px;
	height: 40px;
	line-height: 40px;
	border-radius: 50%;
	text-align: center;
	background-color: rgba(255, 255, 255, 0.3);
	color: #EB7310;
	transition: 0.5s;
}

.icon:hover {
	cursor: pointer;
	background-color: #EB7310;
	color: #fff;
}

a {
	text-decoration: none;
}

li {
	list-style-type: none;
}

button {
	border: none;
}

.row {
	width: 50%;
}

button {
	padding: 17px 30px;
	background-color: #fff;
	color: #EB7310;
	font-weight: 600;
	font-size: 15px;
	border: none;
	margin: 30px 10px 0 0;
	border-radius: 3px;
	cursor: pointer;
	box-shadow: 0 24px 36px -11px rgba(0 0 0 /9%);
}

button i {
	margin-left: 20px;
}

button:hover {
	box-shadow: none;
}

.primary-btn {
	background-color: #EB7310;
	color: #fff;
}

#heading {
	text-align: center;
	padding: 40px 0;
}

#heading h3 {
	font-weight: 600;
	letter-spacing: 1px;
	color: #EB7310;
	text-transform: uppercase;
}

#heading h1 {
	font-size: 30px;
	margin: 20px 0;
	text-transform: capitalize;
}

p {
	line-height: 30px;
	font-size: 18px;
}

.back {
	background-image: url(../public/images/back.png);
	background-size: cover;
	background-attachment: fixed;
	position: absolute;
	top: 0;
	left: 0;
	z-index: -1;
	width: 100%;
	height: 100vh;
	padding-top: 30%;
	padding-right: 50px;
	color: #fff;
	text-align: center;
}

.back h1 {
	font-size: 100px;
	font-weight: 400;
}

.back h2 {
	font-weight: 500;
	font-style: 17px;
	text-transform: uppercase;
}

.margin {
	margin-top: 40.3%;
}

.grid {
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	grid-gap: 30px;
}

.grid2 {
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	grid-gap: 30px;
}

.flex {
	display: flex;
}

.padding {
	padding: 80px 0;
}

.shadow {
	box-shadow: 0 5px 25px -2px rgba(0 0 0 /9%);
	background-color: #fff;
}
@media screen and (max-width: 768px) {
	.grid {
		grid-template-columns: repeat(2, 1fr);
	}
	.back {
		background-position: center;
		padding-top: 50%;
		height: 80vh;
	}
}
