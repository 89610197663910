/* Machine.css */

.machine-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 100vh; /* Full height of the page */
    padding: 20px;
    background-color: #f5f5f5; /* Light background */
  }

  .table-container {
    width: 80%; /* Adjust width as needed */
    margin: auto;
    padding: 20px;
    background-color: #fff; /* White background for table */
    border-radius: 10px; /* Rounded corners */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Light shadow */
  }

  .styled-table {
    width: 100%;
    border-collapse: collapse;
    font-family: 'Arial', sans-serif;
    font-size: 16px;
    text-align: left;
  }

  .styled-table thead tr {
    background-color: #009879; /* Header background color */
    color: white;
    text-align: center;
    font-weight: bold;
  }

  .styled-table th, .styled-table td {
    padding: 12px 15px;
    border: 1px solid #ddd; /* Border around table cells */
  }

  .styled-table tbody tr {
    background-color: #f3f3f3;
  }

  .styled-table tbody tr:nth-of-type(even) {
    background-color: #e9e9e9; /* Alternate row color */
  }

  .styled-table tbody tr:hover {
    background-color: #f1f1f1; /* Hover effect for rows */
  }

  .styled-table td {
    text-align: center;
  }

  .styled-table th {
    background-color: #66b2b2; /* Light teal color for header */
  }

  @media (max-width: 768px) {
    .table-container {
      width: 100%; /* Full width on smaller screens */
    }

    .styled-table th, .styled-table td {
      font-size: 14px; /* Smaller font size for mobile */
    }
  }
