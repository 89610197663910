.testimonal .items {
	padding: 30px;
	transition: 0.5s;
}

.testimonal .img {
	position: relative;
}

.testimonal img {
	width: 100px;
	height: 100px;
	border-radius: 50%;
}

.testimonal i {
	position: absolute;
	bottom: 0;
	left: 60px;
	background-color: #EB7310;
	color: #fff;
}

.testimonal .name h2 {
	font-size: 20px;
	margin-bottom: 5px;
	margin-left: 5px;
}

.testimonal .name span {
	color: #EB7310;
	margin-left: 5px;
}

.testimonal p {
	margin-top: 20px;
	color: grey;
	transition: 0.5s;
}

.testimonal .items:hover {
	background-color: #EB7310;
	color: #fff;
	cursor: pointer;
}

.testimonal .items:hover p,
.testimonal .items:hover span {
	color: #fff;
}

.testimonal .items:hover i {
	color: #EB7310;
	background-color: #fff;
}
