.head {
	padding: 20px 0;
	color: #fff;
}

.logo h1 {
	font-size: 35px;
	font-weight: 900;
}

.head .icon {
	margin-left: 10px;
}

/* HEADER */

header {
	background-color: rgba(255, 255, 255, 0.2);
	margin: 0 30px;
	position: relative;
}

header ul {
	padding: 30px 20px;
}

header li {
	margin-right: 40px;
	position: relative;
}

header .l{
    padding-bottom: 20px;
}

header ul li a {
	color: white;
	font-weight: 500;
	position: relative; /* Ensure the anchor is positioned relative */
	transition: color 0.5s ease;
}

header ul li a::before {
	content: "";
	position: absolute;
	bottom: -2px; /* Adjust as needed for your underline position */
	left: 50%;
	width: 0;
	height: 2px;
	background-color: #EB7310;
	transition: width 0.3s ease, left 0.3s ease; /* Transition for the underline */
	/* transform: translateX(-50%); */
}

header ul li a:hover::before {
	width: calc(100%); /* Adjust the width as per your design */
	left: 0px;
}

.start {
	background-color: #EB7310;
	padding: 30px 70px;
	color: #fff;
	clip-path: polygon(10% 0, 100% 0, 100% 100%, 0 100%);
}

.toggle {
	display: none;
}

.download {
	margin-right: 10px;
}

/* Dropdown Styling */
.dropdown {
	position: absolute;
	top: 100%;
	left: 0;
	background-color: #EB7310;
	padding: 0;
	margin: 0;
	list-style: none;
	display: none;
	opacity: 0;
	visibility: hidden;
	transform: translateY(20px);
	transition: opacity 0.3s ease, visibility 0.3s ease, transform 0.3s ease;
}

li:hover .dropdown {
	display: block;
	opacity: 1;
	visibility: visible;
	transform: translateY(0);
}

.dropdown li {
	margin: 0;
}

.dropdown li a {
	padding: 10px 20px;
	display: block;
	color: #fff;
	font-weight: 500;
	transition: background-color 0.5s ease;
}

.dropdown li a:hover {
	background-color: #17a2b8;
	color: #fff;
}

@media screen and (max-width: 768px) {
	.start {
		clip-path: none;
	}
	header {
		margin: 0;
		background-color: #EB7310;
		position: relative;
	}
	header ul.flexSB {
		display: none;
	}
	header ul li {
		margin-bottom: 20px;
	}
	.toggle {
		display: block;
		background-color: #EB7310;
		color: #fff;
		font-size: 30px;
		position: absolute;
		right: 50px;
		top: -20px;
	}
	.mobile-nav {
		position: absolute;
		top: 7vh;
		left: 0;
		width: 100%;
		background-color: #EB7310;
	}
	.dropdown {
		position: relative;
		top: 0;
		transform: none;
		opacity: 1;
		visibility: visible;
		display: block;
	}
}
