.contact iframe {
	width: 100%;
	height: 100%;
	border: none;
}

.contact .right {
	padding: 50px;
}

.contact h1 {
	font-size: 30px;
	font-weight: 400;
}

.contact p {
	margin: 30px 0;
}

.contact .box p {
	font-size: 17px;
	margin-top: 10px;
}

.contact textarea,
.contact input {
	width: 100%;
	padding: 15px;
	margin-bottom: 15px;
	outline: none;
	border: 1px solid grey;
}

.contact .flexSB input:nth-child(1) {
	margin-right: 10px;
}

.contact h3 {
	margin-top: 20px;
	font-weight: 500;
	margin-bottom: 10px;
}

.contact span {
	color: #EB7310;
	font-weight: 600;
	font-size: 15px;
	word-spacing: 5px;
}

@media screen and (max-width: 768px) {
	.contact .container {
		flex-direction: column;
	}

	.contact iframe {
		height: 50vh;
	}

	.contact .row {
		width: 100%;
	}
}
