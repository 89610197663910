.aboutHome {
	height: auto;
}

.aboutHome #heading {
	text-align: left;
	padding: 0;
}

.aboutHome .left img {
	width: 100%;
	height: 100%;
	object-fit: fill;
    padding-bottom: 50px;
}

.aboutHome .right {
	padding: 80px 50px;
}

.aboutHome .items {
	margin-top: 50px;
}

.aboutHome .item {
	background-color: #fff;
	margin-top: 30px;
	padding: 20px;
	transition: 0.5s;
}

.aboutHome .img {
	width: 200px;
}

.aboutHome img {
	width: 70px;
	height: 70px;
}

.aboutHome .item h2 {
	font-size: 20px;
	margin-bottom: 15px;
}

.aboutHome .item:hover {
	background-color: #EB7310;
	color: #fff;
	cursor: pointer;
	box-shadow: 0 5px 25px -2px rgb(0 0 0 /6%);
}


.awrapper {
	background-image: url(../../../public/images/awrapper.png);
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
	height: 30vh;
	color: #fff;
	opacity: 0.7;
}

.awrapper .box {
	padding: 70px 0;
}

.awrapper img {
	margin-right: 30px;
}

.awrapper h1 {
	font-size: 50px;
}

.awrapper h3 {
	font-size: 20px;
	font-weight: 500;
}

@media screen and (max-width: 768px) {
	.aboutHome {
		margin-top: -30%;
	}
	.aboutHome .container {
		flex-direction: column-reverse;
	}
	.aboutHome .row {
		width: 100%;
	}
	.awrapper {
		height: 58vh;
	}
}
